<template class="">
    <div class="box-border h-relative-to-width w-full border-gray-500 border shadow-lg m-auto z-10">
    </div>
</template>

<script>
export default {
    name: 'Canvas'
}
</script>

<style lang="scss" scoped>

</style>