<template>
  <div>
    <div
      class="grid grid-rows-2 grid-flow-col grid-cols-2 gap-4 w-11/12 m-auto mb-4"
    >
      <Alert
        alertTitle="Attribute auswählen"
        alertText="Wählen Sie Merkmale, Monographien oder Orte aus. "
      ></Alert>

      <DropdownSearch
        selectorOne="Merkmale auswählen / suchen"
        :dropdownOne="dropdownOne"
        :groupOne="true"
        @attributeChange="onAttributeSelection"
        selectorTwo="Monographien auswählen / suchen"
        :dropdownTwo="dropdownTwo"
        :groupTwo="true"
        @monographChange="onMonographSelection"
        selectorThree="Orte auswählen / suchen"
        :dropdownThree="dropdownThree"
        :groupThree="false"
        @placesChange="onPlacesSelection"
        selectorFour="Personen auswählen / suchen"
        :dropdownFour="dropdownFour"
        :groupFour="false"
        @personChange="onPersonSelection"
      ></DropdownSearch>

      <Alert
        alertTitle="Funktionen auswählen"
        alertText="Wählen Sie die Funktionen aus, die auf die Datenpunkte angewendet werden sollen"
      ></Alert>
    </div>

    <div
      class="grid grid-rows-2 grid-flow-row grid-cols-2 gap-4 w-11/12 m-auto mb-4"
    >
      <Canvas id="map"></Canvas>

      <Canvas id="network"></Canvas>
    </div>
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";
import Canvas from "../components/Canvas.vue";
import DropdownSearch from "../components/DropdownSearch.vue";
import { ref, onMounted } from "vue";
import leaflet from "leaflet";
import { environment } from "../environments/environment.vue";

export default {
  components: { Alert, Canvas, DropdownSearch },
  setup() {
    //  Setup dropdown options to reference and fill
    const dropdownOne = ref([]);
    const dropdownTwo = ref([]);
    const dropdownThree = ref([]);
    const dropdownFour = ref([]);

    const selection = ref({})

    function onAttributeSelection(e) {
      selection.value["attributes"] = e
    }

    function onMonographSelection(e) {
      selection.value["monograph"] = e
    }

    function onPlacesSelection(e) {
      selection.value["places"] = e
    } 

    function onPersonSelection(e) {
      selection.value["person"] = e
    }

    //  Access endpoints supposed to fill dropdowns with data

    // Fill first dropdown
    const getGender = async () => {
      const response = await fetch(
        environment.api + "project/get-person-gender"
      );
      const gender = await response.json();

      return gender;
    };

    const getConfession = async () => {
      const response = await fetch(
        environment.api + "project/get-person-confession"
      );
      const confession = await response.json();

      return confession;
    };

    const getNationality = async () => {
      const response = await fetch(
        environment.api + "project/get-person-nationality"
      );
      const nationality = await response.json();

      return nationality;
    };

    // Fill second dropdown
    const getJobDescriptions = async () => {
      const response = await fetch(
        environment.api + "project/get-job-descriptions"
      );
      const jobDescriptions = await response.json();

      return jobDescriptions;
    };

    const getFieldDescriptions = async () => {
      const response = await fetch(
        environment.api + "project/get-field-descriptions"
      );
      const fieldDescriptions = await response.json();

      return fieldDescriptions;
    };

    // Fill third dropdown
    const getPlaces = async () => {
      const response = await fetch(environment.api + "project/get-places");
      const places = await response.json();

      return places;
    };

    onMounted(async () => {
      const gender = await getGender();
      const confession = await getConfession();
      const nationality = await getNationality();
      const jobDescriptions = await getJobDescriptions();
      const fieldDescriptions = await getFieldDescriptions();
      const places = await getPlaces();

      // Fill first dropdown with gender, confession and nationality
      dropdownOne.value = [
        {
          label: "Geschlecht",
          options: gender,
        },
        {
          label: "Konfession",
          options: confession,
        },
        {
          label: "Nationalität",
          options: nationality,
        },
      ];

      dropdownTwo.value = [
        {
          label: "Musikalische Tätigkeiten",
          options: jobDescriptions,
        },
        {
          label: "Träger / Sparte",
          options: fieldDescriptions,
        },
      ];

      // Fill third dropdown with places
      dropdownThree.value = places;

      dropdownFour.value = [];
    });

    // Include European Map

    let map;

    onMounted(() => {
      map = leaflet.map("map").setView([57, 14.5], 4.3);

      leaflet
        .tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            minZoom: 4,
            id: "mapbox/light-v10",
            tileSize: 512,
            zoomOffset: -1,
            accessToken:
              "pk.eyJ1IjoidG9sZ2EtdHVuY2VyIiwiYSI6ImNreGd0aWFkbzB3cTUydm5wNzg0ZTloeHYifQ.tsYCWYCgM02Tm1Nj-m0kEw",
          }
        )
        .addTo(map);
    });

    return {
      dropdownOne,
      dropdownTwo,
      dropdownThree,
      dropdownFour,
      onAttributeSelection,
      onMonographSelection,
      onPlacesSelection,
      onPersonSelection,
      selection
    };
  },
};
</script>

<style lang="scss" scoped></style>
