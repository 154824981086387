<template>
  <div
    class="bg-background-gray border-t-2 border-b-2 border-gray-500 text-gray-500 px-8 py-4 shadow-lg box-border text-left"
    role="alert"
  >
    <p class="font-bold">{{ alertTitle }}</p>
    <p>{{ alertText }}</p>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    alertTitle: {
      type: String,
      default: "<ALERT TITLE>",
      required: true,
    },
    alertText: {
      type: String,
      default: "<ALERT TEXT>",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
