<template>
  <div>
    <Multiselect
      class="float-left z-20"
      v-model="valueDropdownOne"
      mode="tags"
      no-options-text="Leere Liste"
      :placeholder="selectorOne"
      :closeOnSelect="false"
      :searchable="true"
      :createTag="true"
      :options="dropdownOne"
      :limit="10"
      :groups="groupOne"
      :group-select="true"
      :caret="false"
      @change="onAttributeChange"
    />

    <Multiselect
      class="float-left z-20"
      v-model="valueDropdownTwo"
      mode="tags"
      no-options-text="Leere Liste"
      :placeholder="selectorTwo"
      :closeOnSelect="false"
      :searchable="true"
      :createTag="true"
      :options="dropdownTwo"
      :limit="10"
      :groups="groupTwo"
      :group-select="true"
      :caret="false"
      @change="onMonographChange"
    />

    <Multiselect
      class="float-left z-20"
      v-model="valueDropdownThree"
      mode="tags"
      no-options-text="Leere Liste"
      :placeholder="selectorThree"
      :closeOnSelect="false"
      :searchable="true"
      :createTag="true"
      :options="dropdownThree"
      :limit="10"
      :groups="groupThree"
      :group-select="true"
      :caret="false"
      @change="onPlacesChange"
    />

    <Multiselect
      class="float-left z-20"
      v-model="valueDropdownFour"
      mode="tags"
      no-options-text="Leere Liste"
      :placeholder="selectorFour"
      :closeOnSelect="false"
      :searchable="true"
      :createTag="true"
      :options="dropdownFour"
      :limit="10"
      :groups="groupFour"
      :group-select="true"
      :caret="false"
      @change="onPersonChange"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { ref } from "vue";

export default {
  name: "DropdownSearch",
  components: { Multiselect },
  props: {
    selectorOne: {
      type: String,
      default: "<DEFAULT VALUE>",
      required: true,
    },
    selectorTwo: {
      type: String,
      default: "<DEFAULT VALUE>",
      required: true,
    },
    selectorThree: {
      type: String,
      default: "<DEFAULT VALUE>",
      required: true,
    },
    selectorFour: {
      type: String,
      default: "<DEFAULT VALUE>",
      required: true,
    },
    dropdownOne: {
      type: Array,
    },
    dropdownTwo: {
      type: Array,
    },
    dropdownThree: {
      type: Array,
    },
    dropdownFour: {
      type: Array,
    },
    groupOne: {
      type: Boolean,
    },
    groupTwo: {
      type: Boolean,
    },
    groupThree: {
      type: Boolean,
    },
    groupFour: {
      type: Boolean,
    },
  },
  emits: [
    "onAttributeChange",
    "onMonographChange",
    "onPlacesChange",
    "onPersonChange",
  ],
  setup(props, { emit }) {
    const valueDropdownOne = ref();
    const valueDropdownTwo = ref();
    const valueDropdownThree = ref();
    const valueDropdownFour = ref();

    function onAttributeChange(e) {
      emit("attributeChange", e);
      console.log("Test");
    }

    function onMonographChange(e) {
      emit("monographChange", e);
    }

    function onPlacesChange(e) {
      emit("placesChange", e);
    }

    function onPersonChange(e) {
      emit("personChange", e);
    }

    return {
      valueDropdownOne,
      valueDropdownTwo,
      valueDropdownThree,
      valueDropdownFour,
      onAttributeChange,
      onMonographChange,
      onPlacesChange,
      onPersonChange,
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
