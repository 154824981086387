<script>

export default {
  name: "EnvironmentProd"
}

export const environment = {
    api: '/'
};

</script>
  

